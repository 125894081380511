import { Button, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CLIENT_API, MESSAGE_API, NOTIFICATION_API, USER_API } from "../constants/api";


const navigation = [
  {
    title: "Dashboard",
    href: "/client-auth/dashboard",
    icon: "bi bi-speedometer2",
  },
  // {
  //   title: "Inbox",
  //   href: "/client-auth/inbox",
  //   icon: "bi bi-chat-dots",
  // },
  // {
  //   title: "Badges",
  //   href: "/client-auth/badges",
  //   icon: "bi bi-patch-check",
  // },
  // {
  //   title: "Buttons",
  //   href: "/buttons",
  //   icon: "bi bi-hdd-stack",
  // },
  // {
  //   title: "Orders",
  //   icon: "bi bi-card-text",
  //   subItems: [
  //     { title: "All Orders", href: "/client-auth/orders" },
  //     { title: "Add Brief", href: "/client-auth/brief" },
  //   ],
  // },
  // {
  //   title: "Add Brief",
  //   href: "/client-auth/brief",
  //   icon: "bi bi-card-text",
  
  // },
  // {
  //   title: "Grid",
  //   href: "/grid",
  //   icon: "bi bi-columns",
  // },
  // {
  //   title: "Table",
  //   href: "/table",
  //   icon: "bi bi-layout-split",
  // },
  // {
  //   title: "Forms",
  //   href: "/forms",
  //   icon: "bi bi-textarea-resize",
  // },
  // {
  //   title: "Breadcrumbs",
  //   href: "/breadcrumbs",
  //   icon: "bi bi-link",
  // },
  // {
  //   title: "About",
  //   href: "/about",
  //   icon: "bi bi-people",
  // },
];

const Sidebar = () => {
  const myId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const [checkActive, upDateActive] = useState("dark");
  const location = useLocation();
  const [inboxCount, SetInboxCount] = useState(0);
  const [checkDropdown, setDropdown] = useState("close");
  const [checkAnyProject, setCheckAnyProject] = useState(false);
  const [notificationCounter, setNotificationCounter] = useState("0");
  const [noti, setNoti] = useState(null);
  const [update, setUpdate] = useState("0");
  const [collapse, setCollapse] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);




  useEffect(() => {
    const getProjects = async () => {
      const projects = await fetch(CLIENT_API + "/" + myId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await projects.json();
      if (projects.ok) {
        let x = json.data.length;
        if (x == "0") {
          setCheckAnyProject(false);
        } else {
          setCheckAnyProject(true);
        }
      }
    };
    const getClientChats = async () => {
      const response = await fetch(MESSAGE_API + "/client/" + myId);
      const clients = await response.json();
      if (response.ok) {
        SetInboxCount(clients.data.length);
      }
    };

    getProjects();
    getClientChats();
  }, [myId, update, location]);

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const toggleCollapse = (index) => {
    setCollapse((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="bg-dark z-3">
      <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              {navi.subItems ? (
                <>
                  <Button
                    className="nav-link py-3 w-100 text-start"
                    color="link"
                    onClick={() => toggleCollapse(index)}
                  >
                    <i className={navi.icon}></i>
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                    <span className="ml-auto float-right">
                      {collapse[index] ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}
                    </span>
                  </Button>
                  <Collapse isOpen={collapse[index]}>
                    <Nav vertical className="ms-3">
                      {navi.subItems.map((subItem, subIndex) => (
                        <NavItem key={subIndex} className="sidenav-bg">
                          <Link
                            to={subItem.href}
                            className={
                              location.pathname === subItem.href
                                ? "active nav-link py-2"
                                : "nav-link py-2"
                            }
                          >
                            {subItem.title}
                          </Link>
                        </NavItem>
                      ))}
                    </Nav>
                  </Collapse>
                </>
              ) : (
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "active nav-link py-3"
                      : "nav-link py-3"
                  }
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>{navi.title === "Inbox"? <><span className="badge ms-2 text-bg-warning">{inboxCount}</span></> : <></>}
                </Link>
              )}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
